import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import { getAttrSearchParams } from "@src/utils/attribution";
import { isEmpty, omitBy } from "lodash";

const School = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { schoolID, postID, target, conversationID, inviteId, hash, schoolInviteId, schoolId, sl_onb_email } =
    parsedLocation;

  const commonParams = new URLSearchParams(omitBy({ hash: hash?.toString() }, isEmpty));

  useRedirectHandler(() => {
    const getURL = () => {
      if (target === "story" && postID) {
        return `https://teach.classdojo.com/#/schools/story/${postID}?`;
      }
      if (target === "story" && schoolID) {
        return `https://teach.classdojo.com/#/schools/${schoolID}/story?`;
      }
      if (target === "story") {
        return `https://teach.classdojo.com/#/schools/story?`;
      }
      if (schoolID && target === "createEvent") {
        return `https://teach.classdojo.com/#/schools/${schoolID}/events/create?`;
      }
      if (target === "createEvent") {
        return `https://teach.classdojo.com/#/schools/events/create?`;
      }
      if (target === "directory") {
        const dismiss = sl_onb_email === "1" ? "&sl_onb_email=1" : "";
        if (schoolId) {
          return `https://teach.classdojo.com/#/schools/${schoolId}/directory?${dismiss}`;
        }
        return `https://teach.classdojo.com/#/schools/directory?${dismiss}`;
      }
      if (target === "schoolwideSkills") {
        return `https://teach.classdojo.com/#/schools/${schoolId}/points/skills?1=1&`;
      }
      if (target === "directoryStudents") {
        return `https://teach.classdojo.com/#/schools/studentDirectory?`;
      }
      if (target === "inviteTeacher") {
        return `https://teach.classdojo.com/#/schools/directory?modal=inviteTeachers&`;
      }
      if (target === "messages" && schoolID && conversationID) {
        return `https://teach.classdojo.com/#/schools/${schoolID}/messaging/${conversationID}?`;
      }
      if (target === "messages" && conversationID) {
        return `https://teach.classdojo.com/#/schools/messaging/${conversationID}?`;
      }
      if (target === "messages" && schoolID) {
        return `https://teach.classdojo.com/#/schools/${schoolID}/messaging?`;
      }
      if (target === "messages") {
        return `https://teach.classdojo.com/#/schools/messaging?`;
      }
      if (target === "redeem") {
        return `https://teach.classdojo.com/#/singleLinkSignup/${schoolInviteId}?`;
      }
      if (target === "settings") {
        return `https://teach.classdojo.com/#/schools/story?schoolSettings=true&`;
      }
      if (target === "points") {
        return `https://teach.classdojo.com/#/schools/points?`;
      }
      if (target === "manageSkills") {
        return `https://teach.classdojo.com/#/schools/points/skills?`;
      }
      if (target === "join") {
        const query = new URLSearchParams({
          role: parsedLocation.role?.toString() ?? "",
          suggestionId: parsedLocation.suggestionId?.toString() ?? "",
        }).toString();
        if (inviteId) {
          return `https://teach.classdojo.com/#/signup/${inviteId}?${query}&`;
        }
        if (schoolInviteId) {
          return `https://teach.classdojo.com/#/singleLinkSignup/${schoolInviteId}?${query}&`;
        }
      }
      return `https://teach.classdojo.com/#/?`;
    };

    const url = getURL();
    const attribution = getAttrSearchParams();

    return url + attribution.toString() + "&" + commonParams.toString();
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default School;
